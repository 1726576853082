import React from "react"
import { Link } from "gatsby"

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"

// import Accordion from "react-bootstrap/Accordion"

const Faqguid = () => {
  return (
    <div className="acc-smsf">
      <div className="plain-section-wrapper faq-dropdown-wrapper ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="h3-careers">Detailed FAQ Guide - SMSF Lending</h3>
              <div className="faq-accordion mt-4 mt-md-5">
                {/* <Accordion preExpanded={["a", "b", "c", "d", "e", "f", "g", "h" , "i", "j", "k", "l", "m" , "n", "o", "p" ]}> */}
                <Accordion>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How will Oxygen assess my borrowing capacity?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        There must be sufficient income in the SMSF to support
                        the loan. Oxygen looks at the current income of the SMSF
                        based on the most recent tax return and management
                        accounts, plus the proposed income (if purchasing a new
                        property versus refinancing an existing loan).
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Which income types does Oxygen assess?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        Oxygen will look at & take into consideration;
                      </p>
                      <ul>
                        <li>Employer superannuation contributions. </li>
                        <li>
                          Additional voluntary contributions (non-concessional).
                        </li>
                        <li>
                          Rent income on the new investment property (existing
                          rent if refinance).
                        </li>
                        <li>SMSF dividend & interest income</li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are there any restrictions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        There are restrictions on SMSF loans which prevent some
                        transactions from taking place. For example:
                      </p>

                      <ul>
                        <li>Construction loans are not available. </li>
                        <li>
                          Buying a property in your SMSF that you intend to live
                          in as a home is not allowed (owner-occupied business
                          premises are acceptable).
                        </li>
                        <li>
                          Selling a residential property to your SMSF, that you
                          or a related party owns is not allowed (commercial
                          property is acceptable).
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How can I get approval?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        With Oxygen the process of applying for a SMSF loan is
                        straight forward. We are here to help you along the way.
                        Compared to a regular home loan, there are more
                        documents you have to obtain and share with us, which
                        can take some time in preparation.{" "}
                      </p>
                      <p>
                        Reach out via the{" "}
                        <a href="/contact/" target="_blank">
                          contact us form
                        </a>{" "}
                        to start the application
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I use a mortgage broker?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        Yes. You will have to use a broker who is registered
                        with Oxygen, so they can select our products. We have a{" "}
                        <a href="/brokers/" target="_blank">
                          broker finder tool
                        </a>{" "}
                        at the top of our web site you can use, or simply call
                        our 1300 855 699 number and we can help.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="f">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        What is a Self-Managed Superannuation Fund (SMSF)?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        A SMSF is a special type of trust that people can set up
                        to manage their own superannuation.
                      </p>
                      <p>
                        Like a normal super fund, your employer contributions
                        still get paid into the fund, and you can make
                        additional contributions as you see fit.
                      </p>
                      <p>
                        However, unlike a normal super fund, the trustee (either
                        you or your company) has direct control over the assets
                        that your superannuation is invested in.
                      </p>
                      <p>
                        Many people also use their SMSF to help plan for their
                        retirement and assist with tax planning.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="g">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will Oxygen accept my super contributions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        There are laws restricting the use of SMSFs to borrow
                        money, and restricting the recourse of the lender in the
                        event that the trust cannot meet its repayment
                        obligations.
                      </p>
                      <p>
                        A basic outline of the rules a trust must follow in
                        order to borrow money is as follows:
                      </p>

                      <ul>
                        <li>
                          The asset is an asset the SMSF could otherwise legally
                          acquire (if it had the funds).
                        </li>
                        <li>
                          The asset is held on trust for the SMSF using a
                          security trust (known as a security custodian).
                        </li>
                        <li>
                          The SMSF acquires a beneficial interest in the asset
                          from the outset.
                        </li>
                        <li>
                          The SMSF has the right to acquire legal title from the
                          security trustee upon making all loan repayments.
                        </li>
                        <li>
                          The lender must only have limited recourse against one
                          particular asset. This means that in the event of a
                          loan default, the lender must not be able to claim any
                          other assets of the fund.
                        </li>
                        <li>
                          Each borrowing arrangement can only be for a “single
                          acquirable asset”. In the case of strata title or
                          subdivisions, each title is considered a separate
                          asset.
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="h">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Why don’t most banks lend to super funds?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        The majority of lenders do not lend to super funds to
                        buy investment properties because of the smaller size of
                        the market, the complexity of trust loans and because
                        the lender’s recourse is limited to the asset itself.
                      </p>
                      <p>
                        What does this mean? To a lender, it is more work, for a
                        higher risk loan with a lower profit.
                      </p>
                      <p>
                        Oxygen SMSF Loans are straight forward and we can help
                        you along the way.{" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="i">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        When should I apply for an SMSF loan?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        We recommend that you apply for the loan at least two
                        weeks before you begin looking for a property.
                      </p>
                      <p>
                        This process can be expedited if required. However, it
                        is always best to allow additional time to avoid
                        disappointment.
                      </p>
                      <p>
                        To begin with,{" "}
                        <a href="/contact/" target="_blank">
                          contact us{" "}
                        </a>
                        to get the application process started.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="j">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How long does it take to get approval?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        Borrowing in an SMSF is more complicated than applying
                        for a normal home loan!
                      </p>
                      <p>
                        We find that many of our customers take around a week to
                        collate the documents required to apply for the loan,
                        and then it often takes another week to assess and
                        accept the application.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="k">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        How will the loan be structured?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        The loan is made out to the trustee of the SMSF in its
                        capacity as a trustee with the security custodian as
                        mortgagor.
                      </p>
                      <p>
                        Oxygen SMSF Loans have limited recourse, and if the loan
                        is in default, we have no ability to claim the other
                        assets held by the trust. We usually require guarantees
                        from the members of the superannuation fund.{" "}
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="l">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can I get low interest rates?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        Yes! Oxygen SMSF offers the most competitive loan rates
                        in the market.{" "}
                      </p>
                      <p>
                        You are likely to pay a significantly higher rate if you
                        only talk to your current bank.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="m">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Are there no deposit Oxygen SMSF loans?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        It’s a common misconception that you don’t need a
                        deposit to buy a property in your SMSF.
                      </p>
                      <p>
                        In actual fact, you need a minimum of 24% to 25% of the
                        purchase price to cover your 20% deposit and the other
                        costs such as stamp duty. So why do people say that you
                        don’t need a deposit?
                      </p>
                      <p>
                        The reason is that your existing superannuation can be
                        your deposit. If you have $100,000 in a managed super
                        fund, then you can move this to your SMSF and use it as
                        a deposit to buy a property.
                      </p>
                      <p>
                        Effectively this means that you may not need to save a
                        deposit in your own name like you would for a
                        traditional investment property purchased outside of
                        your super fund.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="n">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will Oxygen accept my super contributions?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        If you are close to the retirement age, then Oxygen may
                        not accept your super contributions in the assessment.
                      </p>
                      <p>
                        If you no longer have a personal income then, of course,
                        your super contributions will cease.
                      </p>
                      <p>
                        In this case, Oxygen may shorten the loan term or reduce
                        the amount of the loan so that the rental income can
                        cover the repayments.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="o">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Will Oxygen accept other forms of income?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        Oxygen will look at & take into consideration;
                      </p>
                      <ul>
                        <li>Employer superannuation contributions.</li>
                        <li>
                          Additional voluntary contributions (non-concessional).
                        </li>
                        <li>
                          Rent income on the new investment property (existing
                          rent if refinance).
                        </li>
                        <li>SMSF dividend & interest income </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="p">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Can my SMSF buy a property from my personal portfolio?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p className="mt-3 mb-3">
                        Your SMSF can buy a commercial property that you already
                        own; however, your fund cannot buy a residential
                        property that is owned by you or a related party.
                      </p>
                      <p>
                        The penalties for getting it wrong could include paying
                        a large percentage of your superannuation fund balance
                        as penalty tax – so it is best to get good advice from
                        the outset.
                      </p>
                      <p>
                        We recommend that you discuss any potential tax
                        implications of transferring a property from your name
                        into your SMSF with an accountant that specialises in
                        Self-Managed Superannuation Funds.
                      </p>
                      <p>
                        To begin with,{" "}
                        <a href="/contact/" target="_blank">
                          contact us{" "}
                        </a>{" "}
                        to get the application process started.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqguid
