import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEOSMSF"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Banner from "../../components/Smsf/bannersmsf"
import Offersmsf from "../../components/Smsf/offersmsf"
import ReactWOW from "react-wow"
import CTABanner from "../../components/Common/CTABanner"
import Faqguid from "../../components/Smsf/Faqguid"
import Matadatasmsf from "./matadatasmsf"
import scrollTo from "gatsby-plugin-smoothscroll"


const smsf = () => {
  return (
    <Layout>
      <SEO title="SMSF | Oxygen - Home Loans Made Simple" description="" />
      <Matadatasmsf />
      <Navbar />
      <div className="page-smsf">
        <Banner />

        <section id="borrow">
          <div className="plain-section-wrapper pt-2 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12 pt-4 pb-4">
                  <h3 className="h3-careers">
                    What is a Self-Managed Superannuation Fund? (SMSF)
                  </h3>
                  <ul>
                    <li>
                      A SMSF is a special type of trust that people can set up
                      to manage their own superannuation.
                    </li>
                    <li>
                      Like a normal super fund, your employer contributions
                      still get paid into the fund, and you can make additional
                      contributions as you see fit.
                    </li>
                    <li>
                      However, unlike a normal super fund, the trustee (either
                      you or your company) has direct control over the assets
                      that your superannuation is invested in.
                    </li>
                    <li>
                      Many people also use their SMSF to help plan for their
                      retirement and assist with tax planning.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Offersmsf />

        <section id="applyloan">
          <div className="plain-section-wrapper pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12 pt-4 pb-4">
                  <h3 className="h3-careers">
                    When should I apply for an SMSF loan?
                  </h3>
                  <ul>
                    <li>
                      We recommend that you apply for the loan at least two
                      weeks before you begin looking for a property.
                    </li>
                    <li>
                      This process can be expedited if required. However, it is
                      always best to allow additional time to avoid
                      disappointment.
                    </li>
                    <li>
                      To begin with,{" "}
                      <a href="/contact/" target="_blank">
                        {" "}
                        contact us{" "}
                      </a>
                      to get the application process started.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="support">
          <div className="teal-section-wrapper pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12 pt-4 pb-4">
                  <h3 className="h3-careers">How can I get approval?</h3>
                  <p>
                    With Oxygen the process of applying for a SMSF loan is
                    straight forward. We are here to help you along the way.
                    Compared to a regular home loan, there are more documents
                    you have to obtain and share with us, which can take some
                    time in preparation.
                  </p>
                  <p>
                    Reach out via the contact us form to start the application
                  </p>
                </div>
              </div>
              <ReactWOW delay=".05s" animation="fadeInUp">
                <div className="row d-flex justify-content-center pb-5">
                  <div className="col-12 col-md-5 col-lg-4">
                    <div className="">
                      <a
                        href="/contact/"
                        className="default-btn btn-contact first-time-buyer-color w-100 btn-lg mt-3 mt-md-0"
                        target="_blank"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-5 col-lg-4">
                    <button
                      className="default-btn navy-btn-solid btn-lg mt-2 mt-md-0"
                      onClick={() => scrollTo(".page-smsf", "start")}
                    >
                      Download Guide Now
                    </button>
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </section>

        <section id="quickFaq">
          <div className="plain-section-wrapper pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12 pt-4 pb-4">
                  <h3 className="h3-careers">Quick FAQs - SMSF Lending</h3>
                  <ul>
                    <li>
                      You can borrow up to <strong>$3,000,000</strong> dollars
                      for certain scenarios through Oxygen SMSF Loans.
                    </li>
                    <li>
                      <strong>Standard SMSF Investment Loans:</strong> Oxygen
                      can lend up to 80% of the property value.
                    </li>
                    <li>
                      <strong>Commercial property:</strong> Oxygen can lend up
                      to 80% of the property value for a wide range of
                      commercial property types.
                    </li>
                    <li>
                      <strong>Refinance:</strong> Oxygen can refinance your
                      current SMSF Loan & save you money!
                    </li>
                    <li>
                      <strong>Offset Accounts:</strong> Oxygen SMSF Loans offer
                      the option of an 100% offset account.
                    </li>
                    <li>
                      <strong>Bad credit:</strong> Oxygen can still accept a
                      SMSF Loan even if the SMSF member (guarantor) has had some
                      past credit issues. This is often on a case by case basis.
                    </li>
                    <li>
                      <strong>Low doc (no income evidence):</strong> Oxygen does
                      not offer low doc SMSF Loans.
                    </li>
                    <li>
                      <strong>Construction finance:</strong> Oxygen does not
                      offer SMSF construction finance.
                    </li>
                  </ul>
                  <p>
                    Please talk to your broker or fill in our{" "}
                    <a href="/contact/" target="_blank">
                      contact us form
                    </a>{" "}
                    to discuss your options.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Faqguid />
        <CTABanner />
        <section id="terms-and-condition">
          <div className="plain-section-wrapper pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-12 pt-4 pb-4">
                  <h3 className="h3-careers">Terms & Conditions</h3>
                  <ul>
                    <li>
                      Rates shown apply to eligible SMSF home loans only, up to 60% LVR, loan amount minimum of $50,000 up to max of $2,000,000. Rates are subject to change without notice. Terms, conditions, and eligibility criteria apply.
                    </li>
                    <li>
                      Comparison rates are based on a $150,000 loan amount over 25 years. WARNING: This comparison rate is true only for the examples given and may not include all fees and charges. Different terms, fees or other loan amounts might result in a different comparison rate.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export default smsf
