import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import Iconlight from "../../assets/images/home-loans/smsf/icon-digital-innovation.svg"

const offersmsf = () => {
  return (
    <div className="smsf-smsf-offer">
      <Container className="wrapper">
        <Row>
          <Col md="2" xs="12">
            <div className="wrap-image">
              <img className="mb-3" src={Iconlight} alt="smsf-offer" />
            </div>
          </Col>
          <Col md="10" xs="12">
            <div className="sm-heading">Looking for low interest rates?</div>
            <h3>
              <div className="white"> Oxygen SMSF offers the </div>
              <div className="blue">
                {" "}
                most competitive loan rates{" "}
                <span className="white"> in the market. </span>
              </div>
            </h3>
            <div className="">
              <a
                href="/contact/"
                className="default-btn btn-contact first-time-buyer-color mt-3 mt-md-0"
                target="_blank"
              >
                Contact Us
              </a>

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default offersmsf
