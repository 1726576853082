import React from "react"
import ReactWOW from "react-wow"
import {useStaticQuery, graphql } from "gatsby"
import ForSale from "../../assets/images/home-loans/smsf/icon-for-sale.svg"
import MiniContactForm from "../Index/MiniContactForm"
import Available from "../../assets/images/smsf/available.svg"
import Residential from "../../assets/images/smsf/residential.svg"
import NoFee from "../../assets/images/smsf/noFee.svg"
import Easy from "../../assets/images/smsf/easy.svg"
import Fast from "../../assets/images/smsf/fast.svg"

const Banner = () => {

  const responseData = useStaticQuery(graphql`
      query GetRatesQuery {
        allStrapiRateCards(filter: {Rate_Name: {in: ["Interest_Rate_SMSF", "Comparison_Rate_SMSF"]}}) {
          edges {
            node {
              Rate_Name
              Rate_Value
            }
          }
        }
      }
    `);

  const interestRateSMSF = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "Interest_Rate_SMSF").node.Rate_Value || "N/A";
  const comparisonRateSMSF = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "Comparison_Rate_SMSF").node.Rate_Value || "N/A";


  return (
    <div className="homeloans-hero-banner smsf-hero mb-5">
      <div className="main-banner-wrapper first-time-buyer-color pb-5">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-12 col-md-12 col-lg-6 col-xl-6">
              <div className="main-banner-content first-time-buyer-color mb-md-5 ml-0">
                <img className="mb-5 mb-lg-3 for-sale-icon" src={ForSale} alt="img-for-sale" />
                <h1>SMSF Property Lending Guide</h1>
                <h2 className="first-time-buyer-color mt-0 mt-md-1 mt-lg-1">
                  Our guide on what to consider when it comes to SMSF Loans
                </h2>
                <ReactWOW delay=".05s" animation="fadeInUp">
                  <h2 className="low-rate-title mt-4 mb-2">Low Rate SMSF Loans​</h2>
                  <div className="rates-comparison">
                    <div className="rates-comparison-wapper">
                      <div className="rates-comparison-group">
                        <label>Rates from</label>
                        <div className="rate-wrapper">
                          <h2 className="rate">{interestRateSMSF}</h2>
                          <div className="percentage">
                            <p className="percentage-symbol">
                              % <br /> <span>p.a</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="rates-comparison-group ml-2 ml-md-5 ml-lg-5">
                        <label>Comparison</label>
                        <div className="rate-wrapper">
                          <h2 className="rate">{comparisonRateSMSF}</h2>
                          <div className="percentage">
                            <p className="percentage-symbol">
                              % <br /> <span>p.a</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="low-rate-description mt-3">​​​Oxygen has some of the lowest rate SMSF loans in the market, whether you want to refinance an existing loan or purchase a new property. Check out our range of low rate SMSF loans and start saving thousands today!</p>
                </ReactWOW>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 col-xl-5 d-flex align-items-center">
              <MiniContactForm source="smsf" partner="SMSF" />
            </div>
          </div>
          <ReactWOW delay=".05s" animation="fadeInUp">
            <div className="row justify-content-center justify-content-md-center justify-content-lg-between">
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-4 mb-lg-0">
                <div className="icon-text-group">
                  <img src={Available} alt="Available for Purchase & Refinancee" />
                  <p>Available for Purchase & Refinance</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-4 mb-lg-0">
                <div className="icon-text-group">
                  <img src={Residential} alt="Residential & Commercial Properties" />
                  <p>Residential & Commercial Properties​</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-4 mb-lg-0">
                <div className="icon-text-group">
                  <img src={NoFee} alt="No application fee & no settlement fee" />
                  <p>No application fee & no settlement fee​</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-5 mb-md-0 mb-lg-0">
                <div className="icon-text-group">
                  <img src={Easy} alt="Easy Application process" />
                  <p>Easy Application process​</p>
                </div>
              </div>
              <div className="col-10 col-md-4 col-lg-2 mb-0 mb-md-0 mb-lg-0">
                <div className="icon-text-group">
                  <img src={Fast} alt="Fast & simple Refinance approvals​<" />
                  <p>Fast & simple Refinance approvals​</p>
                </div>
              </div>
            </div>
          </ReactWOW>
        </div>
      </div>
    </div>
  )
}

export default Banner
