import React from "react"
import fail from "../../assets/images/cancel.svg"

let title = "Oops!"
let subTitle = "Form Failed To Submit. Please Try Again!"

const FormFail = props => {
  return (
    <div className="row">
      <div className="col">
        <div className="form-feedback">
          <img src={fail} alt="fail icon" />
          <h3>{props.title || title}</h3>
          <p>{props.subTitle || subTitle}</p>
        </div>
      </div>
    </div>
  )
}

export default FormFail
